// BASEHOME.SCSS


/* GLOBAL STYLES
-------------------------------------------------- */
/* Padding below the footer and lighter body text */

body {
	padding-top: 0;
	padding-bottom: 3rem;
	background-color: #000;
}

.home {
	// background: url(../images/sk_top_hero1.jpg) 50% 0 no-repeat;
}
.carousel {
	margin-top: -188px;
	z-index: 1;
}

.carousel-item {
&.ci1 {
	background: url(../images/sk_carousel01.jpg) 50% 0 no-repeat;
	@media (min-width: 1201px) {
		.hero {
			.container.hero-content {
				// max-width: 1200px;
				// position: relative;
				top: 20rem;
				padding-left: 7.375rem;
				// padding-right: 8rem;
			}
		}
	}
}

&.ci2 {
	background: url(../images/sk_carousel02.jpg) 50% 0 no-repeat;
	@media (min-width: 1201px) {
		.hero {
			.container.hero-content {
				// max-width: 1200px;
				// position: relative;
				top: 42.875rem;
				padding-left: 2rem;
				// padding-right: 8rem;
			}
		}
	}
}

&.ci3 {
	background: url(../images/sk_carousel03.jpg) 50% 0 no-repeat;
	@media (min-width: 1201px) {
		.hero {
			.container.hero-content {
				// max-width: 1200px;
				// position: relative;
				top: 27rem;
				padding-left: 3.625rem;
				// padding-right: 8rem;
			}
		}
	}
}

&.ci4 {
	background: url(../images/sk_carousel04.jpg) 50% 0 no-repeat;
	@media (min-width: 1201px) {
		.hero {
			.container.hero-content {
				// max-width: 1200px;
				// position: relative;
				top: 33.5rem;
				padding-left: 10rem;
				// padding-right: 8rem;
			}
		}
	}
}

}

.carousel-indicators {
	bottom: 60px;
	@media (max-width: 575px) {
		display: none;
	}
}

.page-title,
.unit-title {
	background-color: #000;
	margin-bottom: 0;
	padding-bottom: 1rem;
	text-align: center;
	h3 {
		margin-bottom: 0;
	}
}


/* HERO MODULE
-------------------------------------------------- */
.home {
	.hero {
		color: #fff;
		height: 1000px;
		position: relative;
		// background: url(../images/sk_top_hero1.jpg) bottom 50% no-repeat;
		.hero-image {
			display: none;
		}
		.hero-title {
			font-family: Futura, "Trebuchet MS", Arial, sans-serif;
			letter-spacing: 0.1em;
			font-size: 1rem;
			line-height: 1.2;
			margin-bottom: 10px;
		}
		.hero-lead {
			font-family: serif;
			font-size: 2rem;
			line-height: 1.2;
			margin-bottom: 10px;
			span {
				display: block;
				@media (max-width: 575px) {
					display: inline;
				}
			}
		}
		.scrolldown {
			position: absolute;
			bottom: 0;
			margin-left: -66px;
			left: 50%;
		}
	}
}


.top-flow {
	padding-right: 0;
	padding-left: 0;
	.top-flow-title {
		h2 {
			color: #fff;
			// width: 445px;
			margin: 62px auto 42px;
		}
	}
	.flow {
		position: relative;
		text-align: center;
		width: 400px;
		height: 600px;
		display: block;
		img {
			display: none;
			width: 100%;
		}
		.wrapper {
			width: 100%;
			height: 100%;
			display: block;
			background-color: rgba(0,0,0,0.5);
		&:hover {
			background-color: rgba(0,0,0,0.1);
		}
		}
			h3 {
				position: absolute;
				top: 250px;
				left: 0;
				right: 0;
				color: #fff;
				font-size: 1.625rem;
			}
			.flow-button {
				text-align: center;
				position: absolute;
				top: 332px;
				left: 0;
				right: 0;
			}
		}
	&.col-lg-4.col-sm-6.nopd {
		padding: 0;
	}
}

#tf1 { background: url(../images/top_flow1bw.jpg) 50% 0 no-repeat; }
#tf2 { background: url(../images/top_flow2bw.jpg) 50% 0 no-repeat; }
#tf3 { background: url(../images/top_flow3bw.jpg) 50% 0 no-repeat; }
#tf4 { background: url(../images/top_flow4bw.jpg) 50% 0 no-repeat; }
#tf5 { background: url(../images/top_flow5bw.jpg) 50% 0 no-repeat; }
#tf6 { background: url(../images/top_flow6bw.jpg) 50% 0 no-repeat; }
#tf7 { background: url(../images/top_flow7bw.jpg) 50% 0 no-repeat; }
#tf8 { background: url(../images/top_flow8bw.jpg) 50% 0 no-repeat; }
#tf9 { background: url(../images/top_flow9bw.jpg) 50% 0 no-repeat; }
// #tf10 { background: url(../images/top_flow10bw.jpg) 50% 0 no-repeat; }


.top-qualia {
	padding-bottom: 80px;
	.container {
		padding-right: 0;
		padding-left: 0;
		.top-qualia-title {
			h2 {
				color: #fff;
				// width: 228px;
				margin: 102px auto 42px;
			}
		}
		.goto-qualia {
			.qualia-button {
				margin: 20px auto 20px;
			}
		}
		.qualia-cards {
			margin-bottom: 20px;
			margin-left: 10px;
			margin-right: 10px;
			align-items: flex-start;
			.card {
				color: #fff;
				background-color: #000;
				.card-body {
					padding: 1rem;
				}
				.card-title {
					font-size: 1rem;
					margin-top: 0;
					margin-bottom: .5rem;
					a {
						color: #fff;
					}
				}
				.card-text {
					font-size: .8rem;
					a {
						color: #fff;
						text-decoration: underline;
					}
				}

				a {
					height: auto;
				}

				img.card-img {
					border-radius: 0;
					width: 100%;
					max-height: 240px;
					object-fit: cover;
					object-position: 50% 50%;
				}
			}
		}
	}
}

.top-news {
	background-color: #fff;
	padding-bottom: 50px;
	min-height: 400px;
	h2 {
		font-size: 1.125rem;
		padding-top: 26px;
		padding-bottom: 11px;
		border-bottom: 2px solid #000;
	}
	.container {
		max-width: 1200px;
		padding-right: 0;
		padding-left: 0;
		.goto-news {
			.news-button {
				margin: 20px auto 20px;
			}
		}
	}

	.nav-tabs {
		border-bottom: none;
		.nav-item {
			margin-bottom: 0;
			margin: 0;
			padding: 0;
			text-align: center;
		}
		.nav-link {
			color: #000;
			border-top: 1px solid #000;
			border-bottom: 1px solid #000;
			border-radius: 0;
			padding: 16px 0;
			position: relative;
		&:hover {
			color: #fff;
			background-color: #000;
			border-left: 1px solid #000;
			border-right: 1px solid #000;
		}
		&#news-tab {
			border-left: 1px solid #000;
		}
		&#studycase-tab {
			border-left: 1px solid #000;
			border-right: 1px solid #000;
		}
		&#qanda-tab {
			border-right: 1px solid #000;
		}
		}
		.nav-link.active,
		.nav-item.show .nav-link {
			color: #fff;
			background-color: #000;
			border-color: #000;
			border-radius: 0;
		}
		.nav-link.active,
		.nav-item.show .nav-link,
		.nav-link:hover {
			.triangle {
				position: absolute;
				bottom: -10px;
				left: 50%;
				margin-left: -10px;
				width: 0;
				height: 0;
				border-top: 10px solid #000;
				border-left: 10px solid transparent;
				border-right: 10px solid transparent;
			}
		}
	}

	dl.row {
		margin-left: 0;
		margin-right: 0;
		dt
		 {
			font-size: .875rem;
			border-bottom: 1px solid #dbdbdb;
			margin: 0;
			padding: 0;
			padding-top: 18px;
			padding-bottom: 18px;
			padding-left: .5rem;
		}
		dd {
			font-size: .875rem;
			border-bottom: 1px solid #dbdbdb;
			margin: 0;
			padding: 0;
			padding-top: 18px;
			padding-bottom: 18px;
			padding-right: 3rem;
			background: url(../images/right_arrow_bl.png) 99% 50% no-repeat;
			a {
				color: #000;
			}
		}
	}
}



/* RESPONSIVE CSS
-------------------------------------------------- */
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
	.home {
		.navbar {
			padding-top: 0;
			background: inherit;
		}
		.bg-light {
			// background-color: #000 !important;
			background-color: transparent !important;
		}
		.navbar-nav {
			margin-top: 94px;
			margin-left: -644px;
			.nav-item {
				line-height: 94px;
				background: #fff;
				opacity: 0.8;
				.nav-link {
					font-weight: bold;
					padding-top: 0;
					padding-bottom: 0;
					color: #000;
				&:hover {
					color: #999;
				}
				}
			}
		}
		.navbar-collapse {
			justify-content: flex-start !important;
		}
		.navbar-toggler {
			margin-top: -94px;
		}
		.navbar-brand {
			background: url(../images/sk_logo1.png) 0 0 no-repeat;
			background-size: 644px auto;
			margin-top: -92px;
		}
		.hero {
			.container.hero-content {
				max-width: 1200px;
				position: relative;
				// top: 10rem;
				padding-left: 8rem;
				// padding-right: 8rem;
			}
		}
	}
}


// Medium devices (tablets, less than 1200px)
@media (max-width: 1199px) {
	.home {
		background: none;
		.carousel {
			margin-top: 0;
		}
		.navbar-nav {
			// margin-top: 0;
		}
		.navbar-brand {
			margin: -69px auto 23px;
			height: 70px;
			min-width: 644px;
			background: url(../images/sk_header_logo3.png) 50% 50% no-repeat;
			background-size: 408px auto;
		}
		.navbar-toggler {
			margin-top: -94px;
		}
		.navbar-light {
			a {
				color: #333;
			&:hover {
				color: #999;
			}
			}
		}
		.bg-light {
			background-color: #fff !important;
		}
		.hero {
			background: none;
			height: auto;
			position: relative;
			.hero-image {
				display: inline;
				position: relative;
				width: 100%;
			}
			.container.hero-content {
				// max-width: 960px;
				max-width: 1200px;
				position: absolute;
				top: 10rem;
				padding-left: 8rem;
				// padding-right: 3rem;
				// p.hero-lead {
				// 	font-size: 1.4rem;
				// }
			}
		}
		.top-flow {
			width: 100%;
			.flow {
				height: 276px;
				h3 {
					top: 80px;
					font-size: 1.625rem;
				}
				.flow-button {
					top: 150px;
				}
			}
			.flow.return-top {
				width: 100%;
			}
		}

		.top-news {
			.container {
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {
	.home {
		// .top-qualia {
		// 	.qualia-cards {
		// 		.card {
		// 			img.card-img {
		// 				max-height: 145px;
		// 			}
		// 		}
		// 	}
		// }
	}
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991px) {
	.home {
		.container.hero-content {
			// max-width: 960px;
			padding-left: 3rem;
			padding-right: 3rem;
			p.hero-lead {
				font-size: 1.4rem;
			}
		}
		.top-qualia {
			.qualia-cards {
				.card {
					margin-bottom: 1rem;
					flex-direction: row;
					border-radius: .8rem;
					.card-body {
						padding: 1rem 1rem 0 2rem;
						text-align: left;
					}
					.card-title {
						font-size: 1rem;
						text-align: left;
					}
					.card-text {
						font-size: .8rem;
						text-align: left;
					}

					img.card-img {
						width: 260px;
						align-self: flex-start;
						max-height: 180px;
						margin: 0;
						margin-top: 1rem;
						margin-bottom: .5rem;
					}

				}
			}
		}

		.top-news {
			.container {
				max-width: 100%;
				padding-left: 15px;
				padding-right: 15px;
			}
		}
	}
}


// Extra small devices (portrait phones, less than 767px)
@media (max-width: 767px) {
	.home {
		.navbar-brand {
			margin: -94px auto 0;
			// margin-top: -69px;
			height: auto;
			min-width: 300px;
			background: url(../images/sk_header_logo3.png) 50% 50% no-repeat;
			background-size: auto 40px;
			// margin-bottom: 0;
		}
		.hero {
			.container.hero-content {
				// max-width: 700px;
				position: absolute;
				top: 2rem;
				padding-left: 3rem;
				padding-right: 3rem;
				p.hero-title {
					font-size: 1rem;
				}
				p.hero-lead {
					font-size: .95rem;
					line-height: 1.4;
				}
			}
			.scrolldown {
				display: none;
			}
		}
		.top-flow-title {
			h2 {
				font-size: 1.5rem;
				margin: 42px auto 22px;
			}
		}

		.top-qualia {
			.qualia-cards {
				.card {
					margin-bottom: 2rem;
					flex-direction: column;
					.card-body {
						padding: 0;
						text-align: left;
					}
					.card-title {
						font-size: 1rem;
						line-height: 1.2;
						text-align: left;
					}
					.card-text {
						font-size: .8rem;
						text-align: left;
					}
					img.card-img {
						max-width: 100%;
						display: block;
						// align-self: flex-start;
						margin: 0 auto 1rem;;
					}
				}
			}
		}
		.top-news {
			h2 {
				margin-left: 1rem;
				margin-right: 1rem;
			}
			.nav-item {
				font-size: .8rem;
			}
		}
	}
}
